.text-image-50-50 {
	display: grid;
	row-gap: 3rem;
	padding-block: 3rem;
}

.text-image-50-50__column {
	width: 100%;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.text-image-50-50 {
		column-gap: 50px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		padding-block: 5rem;
	}
}

.text-image-50-50__column__text :is(h2, h3, h4, h5, h6) {
	margin: 0;
	margin-bottom: 2.5rem;
}

.text-image-50-50__column__text a {
	border-color: var(--color-white);
	color: var(--color-white);
}

.text-image-50-50__column__text :is(ul, ol) {
	margin-bottom: 1rem;
}

.text-image-50-50__column__text li {
	line-height: 1.5;
	margin-bottom: 1rem;
	margin-left: 2rem;
	padding: 0;
}

.text-image-50-50__column__image-wrapper img {
	width: 100%;
}
