.stan-wrapper {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	max-width: 1280px;
}

@media (max-width: 1319px) {
	.stan-wrapper {
		padding-right: 20px;
		padding-left: 20px;
	}
}
