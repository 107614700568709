/** This component is very limited for dynamic text.
 * It is only really fit for short cta-labels.
 * We enforce this with a max-char limit of 25.
 */

.call-to-action {
	background-color: var(--color-grey-light);
	padding-top: 3rem;
	padding-bottom: 3rem;
}

@media (min-width: 1024px) {
	.call-to-action {
		padding-top: 5.25rem;
		padding-bottom: 5.25rem;
	}
}

.call-to-action__inner {
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100%;
	max-width: 700px;
}

.call-to-action__link,
.call-to-action__fill-triangle {
	width: 200px;
	height: 160px;
}

@media (min-width: 768px) {
	.call-to-action__link,
	.call-to-action__fill-triangle {
		width: 300px;
		height: 240px;
	}
}

.call-to-action__fill-triangle {
	background-color: rgb(0 0 0 / 0.1);
	clip-path: polygon(0 0, 50% 100%, 100% 0);
	position: absolute;
	top: 0;
}

.call-to-action__fill-triangle:first-child {
	right: calc(50% + 30px);
}

@media (min-width: 768px) {
	.call-to-action__fill-triangle:first-child {
		right: calc(50% + 50px);
	}
}

.call-to-action__fill-triangle:last-child {
	left: calc(50% + 30px);
}

@media (min-width: 768px) {
	.call-to-action__fill-triangle:last-child {
		left: calc(50% + 50px);
	}
}

.call-to-action__link {
	background-color: var(--color-blue);
	border: 0;
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
	color: var(--color-white);
	display: inline-block;
	position: relative;
	text-align: center;
	text-decoration: none;
	transition: background-color 96ms linear;
}

.call-to-action__link:active,
.call-to-action__link:focus,
.call-to-action__link:hover {
	background-color: var(--color-orange);
	color: var(--color-white);
}

.call-to-action__link__label {
	box-sizing: border-box;
	font-size: 1rem;
	font-weight: 700;
	padding-right: 50px;
	padding-left: 50px;
	position: absolute;
	text-transform: uppercase;
	top: 95px;
	left: 0;
	width: 100%;
}

@media (min-width: 768px) {
	.call-to-action__link__label {
		font-size: 1.125rem;
		padding-right: 75px;
		padding-left: 75px;
		top: 140px;
	}
}
