.overview {
	margin-bottom: 0;
	overflow: hidden;
}

@media (min-width: 568px) {
	.overview {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-bottom: 4rem;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.overview__item {
	background-color: transparent;
	background-position: center;
	background-size: cover;
	border: 0;
	position: relative;
}

@media (max-width: 567px) {
	.overview__item {
		display: block;
		margin-bottom: 1rem;
		width: 100%;
	}
}

@media (min-width: 568px) {
	.overview__item {
		flex: 0 0 calc(50% - 1.6666%);
		margin-bottom: 1.25rem;
	}
}

@media (min-width: 1024px) {
	.overview__item {
		flex: 0 0 calc(50% - 0.75rem);
		margin-bottom: 1.25rem;
	}
}

.overview__item::before {
	content: "";
	display: block;
	padding-top: 60%;
}

.overview__item-overlay {
	background-color: rgb(0 0 0 / 0.25);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: background-color 512ms ease-in-out;
}

.overview__item:is(:hover, :focus-visible) .overview__item-overlay {
	background-color: rgb(0 0 0 / 0.7);
}

.overview__item-content {
	color: var(--color-white);
	padding: 1rem;
	position: absolute;
	text-align: center;
	text-shadow: 0 0 6px rgb(0 0 0 / 0.15);
	top: 50%;
	right: 0;
	left: 0;
	transform: translateY(-50%);
	transition:
		transform 512ms ease-in-out,
		opacity 384ms ease-in-out;
}

.overview__title {
	display: block;
	font-size: 1.5rem;
	letter-spacing: 0.05em;
	margin-bottom: 0.75rem;
	text-transform: uppercase;
}

.overview__meta .icon {
	display: inline-block;
	fill: var(--color-orange);
	margin-right: 0.25rem;
	position: relative;
	top: 1px;
	width: 1rem;
	height: 0.9rem;
}

.overview__meta > span {
	color: var(--color-orange);
	font-weight: 700;
}

.locations-overview__image {
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.locations-overview__image {
		object-fit: cover;
		height: 100%;
	}
}
