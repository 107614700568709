.alert {
	background-color: var(--color-grey);
	border: 1px solid rgb(0 0 0 / 0.15);
	border-radius: 3px;
	color: white;
	display: block;
	margin-bottom: 2rem;
	padding: 0.6rem 1rem;
}

.alert a {
	color: currentcolor;
}

.alert:hover {
	border-color: currentcolor;
}

.alert.is-error {
	background-color: var(--color-red);
}

.alert.is-success {
	background-color: var(--color-green);
}

.alert--standalone {
	margin-top: 2rem;
}
