/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.stan-form {
	font-size: 16px !important;
}

.stan-form__label {
	cursor: pointer;
	display: inline-block;
	font-size: 16px !important;
	margin-top: 5px;
	margin-bottom: 5px;
	vertical-align: top;
}

.stan-form__label:first-child {
	margin-top: 0;
}

input[disabled] ~ .stan-form__label,
input:disabled ~ .stan-form__label {
	cursor: not-allowed;
}

.stan-form__label a {
	color: currentColor;
	text-decoration: underline;
}

.stan-form__label a:hover,
.stan-form__label a:focus {
	color: #eb5d0b;
}

.stan-form__field {
	appearance: none;
	background-color: transparent;
	border: 2px solid currentColor;
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	font-size: 16px !important;
	line-height: 1em;
	padding: 10px 12px;
	transition:
		border-color 256ms,
		color 256ms;
	width: 100%;
}

.stan-form__field[invalid] {
	color: #eb5d0b;
}

.stan-form__field[disabled],
.stan-form__field:disabled {
	color: #bdbdbd;
}

.stan-form__group {
	margin: 22px 0;
}

.stan-form__group.stan-form__group--no-margin {
	margin: 0;
}
