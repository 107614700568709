.opening-hours {
}

.opening-hours__day {
	width: 5rem;
}

.opening-hours__seats {
	opacity: 0.5;
	text-align: right;
	width: 5.75rem;
}
