* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	font-family: var(--font-sans);
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
}

@media (min-width: 768px) {
	html {
		font-size: 20px;
	}
}

:is(h1, h2, h3, h4, h5, h6) {
	font-family: var(--font-sans);
	margin-top: 0.875rem;
	margin-bottom: 0.875rem;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	:is(h1, h2, h3, h4, h5, h6) {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
}

:is(h1, h2, h3, h4, h5, h6):first-child {
	margin-top: 0;
}

:is(h1, h2, h3, h4, h5, h6):last-child {
	margin-bottom: 0;
}

:is(h1, h2, h3, h4, h5, h6):only-child {
	margin-top: 0;
	margin-bottom: 0;
}

h1,
h2 {
	font-size: 1.875rem;
	line-height: 1;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	h1 {
		font-size: 2.25rem;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	h1 {
		font-size: 3rem;
	}
}

h2 {
	font-size: 1.5rem;
	line-height: 1;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	h2 {
		font-size: 1.75rem;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	h2 {
		font-size: 2.25rem;
	}
}

h3 {
	font-size: 1.25rem;
	line-height: 1;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	h3 {
		font-size: 1.5rem;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	h3 {
		font-size: 2rem;
	}
}

h4 {
	font-size: 1rem;
	line-height: 1;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	h4 {
		font-size: 1.125rem;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	h4 {
		font-size: 1.5rem;
	}
}

h5 {
	font-size: 0.875rem;
	line-height: 1;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	h5 {
		font-size: 1rem;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	h5 {
		font-size: 1.25rem;
	}
}

h6 {
	font-size: 0.875rem;
	line-height: 1;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	h6 {
		font-size: 1rem;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	h6 {
		font-size: 1.125rem;
	}
}

i,
em,
blockquote {
	font-style: italic;
}

h1 {
	font-size: 2.5rem;
	font-weight: 700;
	margin-top: 0;
	text-transform: uppercase;
}

@media (min-width: 568px) {
	h1 {
		font-size: 4rem;
	}
}

h2 {
	font-size: 1.5rem;
	font-weight: 700;
	margin-top: 0;
	text-transform: uppercase;
}

h2:not([class]) {
	margin-top: 4rem;
	margin-bottom: 3.5rem;
}

h3 {
	font-size: 0.9rem;
	font-weight: 700;
	letter-spacing: 0.05em;
	margin-top: 0;
	margin-bottom: 2rem;
	text-transform: uppercase;
}

h4 {
	font-size: 1rem;
	font-weight: 700;
	margin-top: 0;
}

h5 {
	font-size: 0.8rem;
	font-weight: 700;
	letter-spacing: 0.05em;
	margin-top: 0;
	text-transform: uppercase;
}

p {
	line-height: 1.5;
	margin-bottom: 2rem;
}

p:last-child {
	margin-bottom: 0;
}

a {
	border-bottom: 1px solid var(--color-alpha-blue-25);
	color: var(--color-blue);
	padding-right: 2px;
	padding-left: 2px;
	text-decoration: none;
	transition:
		color 128ms ease-in-out,
		border-color 128ms ease-in-out;
}

a:hover {
	border-color: var(--color-blue);
	color: var(--color-blue);
}
