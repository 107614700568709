.press-banner {
	box-sizing: border-box;
	display: flex;
	margin-top: 4rem;
	padding: 3.75rem 1.25rem;
	justify-content: center;
	position: relative;
	width: 100%;
}

@media (min-width: 768px) {
	.press-banner {
		padding: 3.125rem 1.25rem;
	}
}

.press-banner::before {
	background-color: var(--color-green);
	content: "";
	display: block;
	mask-image: url("../images/zigzag.svg");
	mask-size: 28px 29px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.press-banner__container {
	background-color: var(--color-white);
	flex-grow: 0;
	flex-shrink: 0;
	padding: 1.5625rem;
	position: relative;
}
