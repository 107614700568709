@media (min-width: 1024px) {
	.feature-list {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
}

.feature-list .feature-list__item {
	font-size: 0.9rem;
}

@media (min-width: 1024px) {
	.feature-list .feature-list__item {
		flex: 0 0 33.333%;
	}
}

@media (min-width: 1024px) {
	.feature-list--location {
		display: block;
	}

	.feature-list--location::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (min-width: 1380px) {
	.feature-list--location {
		margin-right: auto;
		margin-left: auto;
		max-width: 960px;
	}
}

@media (min-width: 1024px) {
	.feature-list--location .feature-list__item {
		box-sizing: border-box;
		float: left;
		margin-bottom: 2rem;
		width: 50%;
	}
}

@media (min-width: 1024px) {
	.feature-list--location .feature-list__item:nth-child(2) {
		float: right;
	}
}

.feature-list__item {
	box-sizing: border-box;
	padding: 0 2rem;
	text-align: center;
}

@media (max-width: 1023px) {
	.feature-list__item {
		border-bottom: 1px solid #efefef;
		margin: 0 auto 2.25rem;
		padding: 0 1rem 2rem;
		max-width: 510px;
	}

	.feature-list__item:last-child {
		border-bottom: 0;
		margin-bottom: 0;
	}
}

@media (max-width: 767px) {
	.feature-list__item {
		max-width: 320px;
	}
}

@media (min-width: 1024px) {
	.feature-list__item {
		padding: 0 1rem;
	}
}

@media (min-width: 1380px) {
	.feature-list__item {
		padding: 0 2rem;
	}
}

.feature-list__item.has-no-padding {
	padding-right: 0;
	padding-left: 0;
}

.feature-list__item table {
	margin-bottom: 1.5rem;
}

.feature-list__title {
	color: var(--color-orange);
}

@media (min-width: 1024px) and (max-width: 1379px) {
	.feature-list__title {
		margin-right: -2em;
		margin-left: -2em;
	}
}

.feature-list__title .icon {
	display: inline-block;
	fill: var(--color-orange);
	margin-right: 0.35rem;
	margin-left: -1.625em;
	position: relative;
	top: 3px;
	width: 1rem;
	height: 1rem;
}

@media (min-width: 1024px) and (max-width: 1379px) {
	.feature-list__title .icon {
		margin-left: 0;
	}
}

.feature-list__title ~ .opening-hours {
	margin-top: 2rem;
}

.feature-list__title + .opening-hours {
	margin-top: 0;
}
