.mobile-navigation {
	box-shadow: -10px 0 20px rgb(0 0 0 / 0.15) inset;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	transform: translateX(-100%);
	transition: transform 512ms ease-in-out;
	width: calc(100vw - 65px);
}

.l-styleguide .mobile-navigation {
	position: absolute;
}

.is-showing-navigation .mobile-navigation {
	transform: translateX(0);
}

@media (min-width: 568px) {
	.mobile-navigation {
		width: calc(100vw - 95px);
	}
}

.mobile-navigation__link {
	border: none;
	border-bottom: 1px solid rgb(0 0 0 / 0.1);
	color: black;
	display: block;
	font-size: 1.5rem;
	font-weight: 700;
	letter-spacing: 0.05em;
	padding: 0.85rem 1rem 0.7rem 1rem;
	text-transform: uppercase;
}

.mobile-navigation__item.is-active .mobile-navigation__link,
.mobile-navigation__link:hover,
.mobile-navigation__link:active {
	border-color: rgb(0 0 0 / 0.1);
	color: var(--color-white);
}

.mobile-navigation__list-title {
	border-bottom: 1px solid rgb(0 0 0 / 0.1);
	color: rgb(0 0 0 / 0.5);
	display: block;
	font-size: 1.75rem;
	font-weight: 700;
	padding: 0.775rem 1rem 0.625rem;
	text-transform: uppercase;
}
