.doormat {
	position: relative;
}

.doormat::after {
	clear: both;
	content: "";
	display: table;
}

.doormat__count.is-non-zero {
	color: var(--color-white);
	font-weight: 700;
}

.doormat__count.is-non-zero::after {
	content: attr(live-count);
}

.doormat__title {
	display: block;
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 1rem;
}

.doormat__section:not(:last-child) {
	margin-bottom: 2.5rem;
}

@media (min-width: 768px) {
	.doormat__section {
		float: left;
		width: 45%;
	}

	*.doormat__section:last-of-type {
		float: right;
	}

	@media (min-width: 1024px) {
		*.doormat__section:last-of-type {
			width: 25%;
		}
	}
}

.doormat__image {
	display: none;
}

@media (min-width: 1024px) {
	.doormat__image {
		background-image: url("../images/iphone.png");
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		display: block;
		position: absolute;
		top: -4.5rem;
		right: 30%;
		width: 9rem;
		height: calc(100% + 6.75rem);
	}
}

@media (min-width: 1024px) {
	.doormat__image {
		top: -5.5rem;
		right: 30%;
		width: 11rem;
		height: calc(100% + 10rem);
	}
}
