.theme-blue {
	background-color: var(--color-blue);
	color: var(--color-white);
}

.theme-green {
	background-color: var(--color-green);
	color: var(--color-white);
}

.theme-light-grey {
	background-color: var(--color-grey-light);
	color: var(--color-black);
}

.theme-orange {
	background-color: var(--color-orange);
	color: var(--color-white);
}

.theme-yellow {
	background-color: var(--color-yellow);
	color: var(--color-white);
}

.theme-white {
	background-color: var(--color-white);
	color: var(--color-black);
}

.theme-color-orange {
	color: var(--color-orange);
}
