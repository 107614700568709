/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.stan-button {
	background-color: transparent;
	border: 2px solid currentColor;
	border-radius: 30px;
	color: currentColor;
	display: inline-block;
	font-family: titling-gothic-fb-condensed, "Arial Narrow", "Arial", sans-serif;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 1.2;
	padding: 6px 15px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: color 96ms linear;
}

@media (min-width: 1024px) {
	.stan-button {
		font-size: 15px;
		padding: 9px 20px;
	}
}

@media (min-width: 1380px) {
	.stan-button {
		font-size: 18px;
		line-height: 1.25;
		padding: 12px 25px;
	}
}

.stan-button:disabled,
.stan-button[disabled] {
	color: rgb(177 177 177);
	cursor: default;
}

.stan-button:disabled:hover,
.stan-button[disabled]:hover {
	color: rgb(177 177 177);
	cursor: default;
}

.stan-button:focus,
.stan-button:hover {
	color: #00dd00;
}

.stan-button:focus {
	outline: 0;
}

.stan-button:hover {
	cursor: pointer;
}

.stan-button[submitting] {
	animation: SUBMITTING 480ms linear infinite alternate;
}

@keyframes SUBMITTING {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0.8;
	}
}
