.stan-navigation-overview {
	background-color: #0000ff;
	box-sizing: border-box;
	color: #ffffff;
	display: block;
	padding-top: 20px;
	padding-bottom: 20px;
}

@media (min-width: 1380px) {
	.stan-navigation-overview {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

@media (min-width: 568px) {
	.stan-navigation-overview__wrapper {
		display: flex;
		flex-wrap: wrap;
	}
}

@media (min-width: 568px) {
	.stan-navigation-overview__column {
		flex-basis: 100%;
	}
}

@media (min-width: 768px) {
	.stan-navigation-overview__column {
		flex-basis: calc(50% - (40px / 2));
	}
}

@media (min-width: 1024px) {
	.stan-navigation-overview__column {
		flex-basis: calc((100% - (65px * 2)) / 3);
	}
}

@media (min-width: 1380px) {
	.stan-navigation-overview__column {
		flex-basis: calc((100% - (80px * 2)) / 3);
	}
}

@media (min-width: 768px) {
	.stan-navigation-overview__column:nth-child(even) {
		margin-left: 40px;
	}
}

@media (max-width: 767px) {
	.stan-navigation-overview__column + .stan-navigation-overview__column {
		margin-top: 20px;
	}
}

@media (min-width: 1024px) {
	.stan-navigation-overview__column + .stan-navigation-overview__column {
		margin-left: 65px;
	}
}

@media (min-width: 1380px) {
	.stan-navigation-overview__column + .stan-navigation-overview__column {
		margin-left: 80px;
	}
}

@media (min-width: 768px) {
	.stan-navigation-overview__column.stan-navigation-overview__column--nav {
		display: flex;
	}
}

@media (min-width: 568px) and (max-width: 1023px) {
	.stan-navigation-overview__column.stan-navigation-overview__column--meta {
		display: flex;
		flex-basis: 100%;
		margin-top: 40px;
	}
}

.stan-navigation-overview__title {
	font-family: titling-gothic-fb-condensed, "Arial Narrow", "Arial", sans-serif;
	font-size: 16px;
	letter-spacing: 1px;
	line-height: 1.63;
	margin-top: 0;
	margin-bottom: 15px;
	text-transform: uppercase;
}

@media (min-width: 1380px) {
	.stan-navigation-overview__title {
		font-size: 18px;
		line-height: 1.67;
	}
}

.stan-navigation-overview__title:only-child,
.stan-navigation-overview__title:last-child {
	margin-bottom: 0;
}

.sg-kitchensink__fallback-font .stan-navigation-overview__title,
html:not(.wf-active) .stan-navigation-overview__title {
	font-family: "Arial Narrow", "Arial", sans-serif;
	letter-spacing: 0.55px;
}

@media (min-width: 1380px) {
	.sg-kitchensink__fallback-font .stan-navigation-overview__title,
	html:not(.wf-active) .stan-navigation-overview__title {
		letter-spacing: 0.5px;
	}
}

@media (min-width: 768px) {
	.stan-navigation-overview__logo-container,
	.stan-navigation-overview__about-navigation-container {
		flex-basis: calc(50% - 10px);
	}
}

@media (min-width: 1024px) {
	.stan-navigation-overview__logo-container,
	.stan-navigation-overview__about-navigation-container {
		flex-basis: calc(50% - 20px);
	}
}

@media (min-width: 1380px) {
	.stan-navigation-overview__logo-container,
	.stan-navigation-overview__about-navigation-container {
		flex-basis: calc(50% - 33px);
	}
}

@media (max-width: 767px) {
	.stan-navigation-overview__logo-container:not(:first-child),
	.stan-navigation-overview__about-navigation-container:not(:first-child) {
		margin-top: 40px;
	}
}

@media (min-width: 768px) {
	.stan-navigation-overview__logo-container:not(:first-child),
	.stan-navigation-overview__about-navigation-container:not(:first-child) {
		margin-left: 20px;
	}
}

@media (min-width: 1024px) {
	.stan-navigation-overview__logo-container:not(:first-child),
	.stan-navigation-overview__about-navigation-container:not(:first-child) {
		margin-left: 40px;
	}
}

@media (min-width: 1380px) {
	.stan-navigation-overview__logo-container:not(:first-child),
	.stan-navigation-overview__about-navigation-container:not(:first-child) {
		margin-left: 65px;
	}
}

.stan-navigation-overview__logo {
	background-image: url("../images/powered-by-stan.svg");
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
	border-bottom: none;
	color: #ffffff;
	display: block;
	padding-right: 0;
	padding-left: 0;
	width: 120px;
	height: 56px;
}

.stan-navigation-overview__logo:focus,
.stan-navigation-overview__logo:hover {
	color: #00dd00;
}

.stan-navigation-overview__logo > svg {
	display: block;
	fill: currentColor;
	width: 100%;
	height: 100%;
}

@media (min-width: 568px) {
	.stan-navigation-overview__general-information {
		display: flex;
	}
}

.stan-navigation-overview__general-information a:not([class]) {
	color: currentColor;
	text-decoration: underline;
	transition: color 96ms linear;
}

.stan-navigation-overview__general-information a:focus:not([class]) {
	outline: 0;
}

.stan-navigation-overview__general-information a:focus:not([class]),
.stan-navigation-overview__general-information a:hover:not([class]) {
	color: #00dd00;
	text-decoration: none;
}

@media (max-width: 567px) {
	.stan-navigation-overview__general-information__column + .stan-navigation-overview__general-information__column {
		margin-top: 20px;
	}
}

@media (min-width: 568px) {
	.stan-navigation-overview__general-information__column + .stan-navigation-overview__general-information__column {
		margin-left: 20px;
	}
}

@media (min-width: 1024px) {
	.stan-navigation-overview__general-information__column + .stan-navigation-overview__general-information__column {
		margin-left: 40px;
	}
}

@media (min-width: 1380px) {
	.stan-navigation-overview__general-information__column + .stan-navigation-overview__general-information__column {
		margin-left: 65px;
	}
}

.stan-navigation-overview__general-information__column.stan-navigation-overview__general-information__column--max-width > * {
	width: auto;
	width: max-content; /* try and keep address info etc. on one line */
	max-width: 175px; /* make sure lots of content don't break layout */
}

.stan-navigation-overview__subscribe {
	margin-top: 40px;
}

@media (min-width: 568px) and (max-width: 767px) {
	.stan-navigation-overview__subscribe {
		max-width: calc(50% - (20px / 2));
	}
}

.stan-navigation-overview__subscribe__email::placeholder {
	color: currentColor;
	opacity: 0.7;
}

.stan-navigation-overview__subscribe__button {
	display: block;
	margin-left: auto;
}

@media (min-width: 568px) {
	.stan-navigation-overview__partners-container,
	.stan-navigation-overview__social-navigation-container {
		flex-basis: calc(50% - (20px / 2));
	}
}

@media (min-width: 768px) {
	.stan-navigation-overview__partners-container,
	.stan-navigation-overview__social-navigation-container {
		flex-basis: calc(50% - (40px / 2));
	}
}

@media (min-width: 1024px) {
	.stan-navigation-overview__partners-container,
	.stan-navigation-overview__social-navigation-container {
		flex-basis: 100%;
	}
}

@media (min-width: 568px) {
	.stan-navigation-overview__partners-container:nth-child(even),
	.stan-navigation-overview__social-navigation-container:nth-child(even) {
		margin-left: 20px;
	}
}

@media (min-width: 768px) {
	.stan-navigation-overview__partners-container:nth-child(even),
	.stan-navigation-overview__social-navigation-container:nth-child(even) {
		margin-left: 40px;
	}
}

@media (min-width: 1024px) {
	.stan-navigation-overview__partners-container:nth-child(even),
	.stan-navigation-overview__social-navigation-container:nth-child(even) {
		margin-top: 20px;
		margin-left: 0;
	}
}

@media (min-width: 1024px) {
	.stan-navigation-overview__partners-container + .stan-navigation-overview__social-navigation-container {
		margin-top: 0;
	}
}

.stan-navigation-overview__social-navigation-container:not(:last-child):not(:only-child) {
	margin-bottom: 20px;
}

.stan-navigation-overview__partners {
	font-size: 0;
}

.stan-navigation-overview__partners__item {
	display: inline-block;
	margin-right: 20px;
	margin-bottom: 20px;
	vertical-align: middle;
}

.stan-navigation-overview__partners__item:focus,
.stan-navigation-overview__partners__item:hover {
	filter: invert(59%) sepia(92%) saturate(3872%) hue-rotate(87deg) brightness(109%) contrast(122%);
}

.stan-navigation-overview__legal__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (min-width: 568px) {
	.stan-navigation-overview__legal__wrapper {
		flex-direction: row;
		justify-content: space-between;
	}
}

@media (max-width: 567px) {
	.stan-navigation-overview__legal-navigation-container {
		margin-top: 20px;
	}
}
