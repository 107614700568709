.accordion {
	margin-bottom: 2.5rem;
}

.accordion-title {
	margin-top: 2rem;
	margin-bottom: 1.5rem;
}

@media (min-width: 1024px) {
	.accordion-title {
		margin-top: 4rem;
		margin-bottom: 3.5rem;
	}
}

/* #region Item */
.accordion__item {
	border-top: 1px solid #e5e5e5;
	padding: 0;
}

*.accordion__item:first-of-type {
	border-top: 0;
	padding-top: 0;
}
/* #endregion Item */

/* #region Heading */
.accordion__heading {
	font-weight: 700;
	margin-bottom: 0;
	padding: 0;
}

.accordion__heading.is-active {
	color: var(--color-orange);
}

.accordion__trigger:hover,
.accordion__trigger:focus {
	color: var(--color-orange);
	cursor: pointer;
	outline: 0;
}
/* #endregion Heading */

/* #region Trigger */
.accordion__trigger {
	appearance: none;
	background-color: transparent;
	border: none;
	color: currentcolor;
	display: block;
	font-family: inherit;
	font-weight: inherit;
	padding: 1rem 0;
	text-align: left;
	width: 100%;
}

html:not(.no-js, .js-loading-error) .accordion__trigger:is(:hover, :focus) {
	cursor: pointer;
}

html:not(.no-js, .js-loading-error) .accordion__trigger:hover .accordion__trigger__title {
	color: var(--color-orange);
}

html:is(.no-js, .js-loading-error) .accordion__trigger {
	display: none;
}

.accordion__trigger span {
	pointer-events: none;
}

.accordion__trigger__title {
	font-size: 1rem;
	text-align: left;
}

html:is(.no-js, .js-loading-error) .accordion__trigger__title {
	cursor: text;
	pointer-events: auto;
	user-select: text;
}
/* #endregion Trigger */

/* #region Panel */
.accordion__panel {
	color: var(--color-black);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	margin-bottom: 1rem;
}

.accordion__panel p {
	padding-bottom: 0;
}

html:not(.no-js, .js-loading-error) .accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}
/* #endregion Panel */
