.site-footer {
	position: relative;
	width: 100%;
}

.site-footer__doormat {
	padding: 2.25rem 0;
	position: relative;
	z-index: 3;
}

@media (min-width: 1024px) {
	.site-footer__doormat {
		padding: 4.5rem 0;
	}
}

.site-footer__partners,
.site-footer__stan {
	position: relative;
	z-index: 1;
}
