.text-page {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

@media (min-width: 1024px) {
	.text-page {
		padding-top: 5.25rem;
		padding-bottom: 5.25rem;
	}
}

.theme-light-grey + .theme-light-grey .text-page,
.theme-white + .theme-white .text-page {
	padding-top: 0;
}

.text-page__title {
	color: var(--color-orange);
	margin-right: auto;
	margin-bottom: 2.5rem;
	margin-left: auto;
	text-align: center;
}

@media (min-width: 1024px) {
	.text-page__title {
		margin-bottom: 5rem;
	}
}

.text-page__title:first-child:last-child {
	margin: 0 auto;
}

.text-page__call-to-action {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.text-page__content + .text-page__call-to-action {
	margin-top: 4.75rem;
}

.text-page__content ul,
.text-page__content ol {
	margin-bottom: 1rem;
}

.text-page__content li {
	line-height: 1.5;
	margin-bottom: 1rem;
	margin-left: 2rem;
	padding: 0;
}

.text-page__content.is-centered {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.text-page--shuffle-button {
	padding-top: 0;
}
