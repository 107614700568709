.location-data-error-dialog {
	background-color: var(--color-green);
	display: none;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 1rem;
	bottom: 1rem;
	width: calc(100% - 2rem);
	z-index: 1000;
}

@media (min-width: 768px) {
	.location-data-error-dialog {
		width: 500px;
	}
}

.location-data-error-dialog[data-state="opening"],
.location-data-error-dialog[data-state="open"],
.location-data-error-dialog[data-state="closing"] {
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.location-data-error-dialog:focus {
	outline: none;
}

.location-data-error-dialog__content {
	box-sizing: border-box;
	padding: 1rem;
}

.location-data-error-dialog__actions {
	display: flex;
	flex-direction: column;
}

@media (min-width: 768px) {
	.location-data-error-dialog__actions {
		flex-direction: row;
		align-items: center;
	}
}

.location-data-error-dialog__actions > * {
	margin-top: 0;
}

@media (max-width: 767px) {
	.location-data-error-dialog__actions > *:not(:last-child) {
		margin-bottom: 1rem;
	}
}

@media (min-width: 768px) {
	.location-data-error-dialog__actions > *:not(:last-child) {
		margin-right: 1rem;
	}
}

@media (max-width: 767px) {
	.location-data-error-dialog__actions button {
		width: 100%;
	}
}
