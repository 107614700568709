.site-notice {
	background-color: var(--color-background);
	background-image: url("../images/vertical-line-green.svg");
	background-size: 6px 1px;
	box-shadow: 0 -2px 4px rgb(0 0 0 / 0.05);
	color: var(--color-green);
	font-size: 0.8rem;
	font-weight: 700;
	text-align: center;
	z-index: 1;
}

.site-notice__inner {
	background-color: var(--color-background);
	border-bottom: 1px solid currentColor;
	box-sizing: border-box;
	font-weight: 700;
	line-height: 1.15;
	margin-right: auto;
	margin-left: auto;
	padding: 1rem;
	width: 90%;
}

@media (min-width: 768px) {
	.site-notice__inner {
		padding-right: calc(520px * 0.1);
		padding-left: calc(520px * 0.1);
		width: 520px;
	}
}

@media (min-width: 822px) {
	.site-notice__inner {
		padding-right: calc(790px * 0.2);
		padding-left: calc(790px * 0.2);
		width: 790px;
	}
}
