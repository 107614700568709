.image-grid {
	font-size: 0;
	padding-top: 1.5rem;
	padding-bottom: 0.5rem;
}

@media (min-width: 1024px) {
	.image-grid {
		padding-top: 5rem;
		padding-bottom: 3rem;
	}
}

.image-grid__image {
	display: inline-block;
	margin-right: 1rem;
	margin-bottom: 1rem;
}

.image-grid__image:nth-child(1),
.image-grid__image:nth-child(2),
.image-grid__image:nth-child(3) {
	width: calc((100% - 2 * 1rem) / 3);
}

.image-grid__image:nth-child(3),
.image-grid__image:nth-child(6) {
	margin-right: 0;
}

.image-grid__image:nth-child(4),
.image-grid__image:nth-child(6) {
	width: calc((100% - 2 * 1rem) / 4);
}

.image-grid__image:nth-child(5) {
	width: calc((100% - 2 * 1rem) / 2);
}

.image-grid__text {
	padding-block-start: 1.5rem;
	padding-block-end: 3rem;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.image-grid__text {
		padding-block-start: 1rem;
		padding-block-end: 5rem;
	}
}

.image-grid__text__content :is(h2, h3, h4, h5, h6) {
	margin: 0;
	margin-bottom: 2.5rem;
}

.image-grid__text__content a {
	border-color: var(--color-white);
	color: var(--color-white);
}

.image-grid__text__content :is(ul, ol) {
	margin-bottom: 1rem;
}

.image-grid__text__content li {
	line-height: 1.5;
	margin-bottom: 1rem;
	margin-left: 2rem;
	padding: 0;
}
