.stan-site-footer {
	font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.57;
}

@media (min-width: 1380px) {
	.stan-site-footer {
		font-size: 16px;
		line-height: 1.44;
	}
}

/* Reset Study360-styles influencing STAN-links */

.stan-site-footer a {
	border-bottom: none;
	padding-right: 0;
	padding-left: 0;
}

.stan-site-footer__legal {
	padding-top: 20px;
	padding-bottom: 20px;
}

@media (min-width: 1380px) {
	.stan-site-footer__legal {
		padding-top: 24px;
		padding-bottom: 24px;
	}
}

.stan-site-footer__legal__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (min-width: 568px) {
	.stan-site-footer__legal__wrapper {
		flex-direction: row;
		justify-content: space-between;
	}
}

.stan-site-footer__copyright {
	text-transform: uppercase;
}

@media (max-width: 567px) {
	.stan-site-footer__legal-navigation-container {
		margin-top: 20px;
	}
}
