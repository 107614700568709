.button {
	appearance: none;
	background-color: var(--color-blue);
	border: 0;
	border-radius: 0;
	color: var(--color-white);
	cursor: pointer;
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 0.8rem;
	font-weight: 600;
	letter-spacing: 0.05em;
	line-height: 1;
	padding: 0.85rem 1.25rem 0.625rem;
	position: relative;
	text-transform: uppercase;
	transition: background-color 96ms ease-in-out;
}

.button:hover,
.button:focus {
	background-color: var(--color-orange);
	color: var(--color-white);
	outline: 0;
}

.button.button--small {
	padding: 0.5rem 0.65rem 0.3125rem;
}

.button.button--red {
	background-color: var(--color-red);
}

.button.button--red:hover,
.button.button--red:focus {
	background-color: var(--color-orange);
}

.button.button--green {
	background-color: var(--color-green);
}

.button.button--green:hover,
.button.button--green:focus {
	background-color: var(--color-blue);
}

.button.button--inverted {
	background-color: var(--color-orange);
}

.button.button--inverted:hover,
.button.button--inverted:focus {
	background-color: var(--color-blue);
}

.button.button--ghost {
	background-color: var(--color-alpha-orange-0);
	border: 1px solid var(--color-blue);
	color: var(--color-blue);
	transition:
		background-color 96ms ease-in-out,
		border-color 96ms ease-in-out,
		color 96ms ease-in-out;
}

.button.button--ghost:hover,
.button.button--ghost:focus {
	background-color: var(--color-orange);
	border-color: var(--color-orange);
	color: var(--color-white);
}

/* button variations depending on the theme they are in */
.theme-blue .button {
	background-color: var(--color-green);
}

.theme-blue .button:hover {
	background-color: var(--color-orange);
}

.theme-orange .button:hover {
	background-color: var(--color-green);
}
