.switch {
	-mrh-resets: button; /* button reset */
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.switch__label {
	font-size: 1rem;
	hyphens: auto;
	line-height: 1.5;
	margin-right: 8px;
	overflow-wrap: anywhere;
	pointer-events: none;
	text-align: left;
}

.switch__toggle {
	background-color: var(--color-grey);
	border-radius: 99999px;
	cursor: pointer;
	flex-basis: 40px;
	flex-grow: 0;
	flex-shrink: 0;
	pointer-events: none;
	position: relative;
	height: 20px;
}

@media (min-width: 1380px) {
	.switch__toggle {
		flex-basis: 70px;
		height: 35px;
	}
}

.switch__toggle__bullet {
	background-color: var(--color-white);
	border-radius: 50%;
	display: block;
	margin: 3px;
	position: absolute;
	left: 0;
	transition: left 256ms ease-in-out;
	width: 14px;
	height: 14px;
}

.switch[aria-pressed="true"] .switch__toggle__bullet {
	left: calc(40px - 14px - 6px);
}

.switch[aria-pressed="true"] .switch__toggle {
	background-color: var(--color-orange);
}

@media (min-width: 1380px) {
	.switch__toggle__bullet {
		margin: 4px;
		width: 27px;
		height: 27px;
	}

	.switch[aria-pressed="true"] .switch__toggle__bullet {
		left: calc(70px - 27px - 8px);
	}
}
