.pagination {
	display: flex;
	margin: 0 auto;
	padding-bottom: 2rem;
	justify-content: space-around;
	max-width: 140px;
}

@media (min-width: 568px) {
	.pagination {
		padding-bottom: 4rem;
	}
}

.pagination__item {
	appearance: none;
	list-style: none;
}
