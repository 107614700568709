.locations-filter {
	display: grid;
	grid-row-gap: 1.5625rem;
	align-items: flex-end;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.locations-filter {
		grid-column-gap: 100px;
		grid-template-columns: calc(70% - 100px) 30%;
	}
}

.locations-filter__label {
	display: none;
}

/* #region Buttons */
.locations-filter__switch-buttons {
	display: grid;
	grid-row-gap: 0.625rem;
	list-style: none;
}

@media (min-width: 370px) {
	.locations-filter__switch-buttons {
		grid-column-gap: 20px;
		grid-template-columns: repeat(2, 1fr);
	}
}

/* from-xsmall */
@media (min-width: 500px) {
	.locations-filter__switch-buttons {
		grid-column-gap: 40px;
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.locations-filter__switch-buttons {
		grid-column-gap: 80px;
	}
}

.locations-filter__switch-buttons__item {
	display: flex;
}
/* #endregion Buttons */

/* #region Sorting */
.locations-filter__sorting {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.locations-filter__sorting .form-input--select {
	width: 60%;
}

@media (min-width: 1380px) {
	.locations-filter__sorting .form-input--select {
		width: 65%;
	}
}
/* #endregion Sorting */

.locations-filter__find_my_location {
	align-self: flex-start;
}
