.partners-study360 {
	padding: 4rem 0 5.5rem;
}

.partners-study360__title {
	color: var(--color-grey);
	font-weight: 400;
	margin-bottom: 2rem;
	position: relative;
	text-align: center;
}

@media (min-width: 768px) {
	.partners-study360__title {
		margin-bottom: 4rem;
	}
}

.partners-study360__title span {
	background-color: var(--color-white);
	display: inline-block;
	padding: 0 1rem;
	position: relative;
	text-align: center;
	z-index: 2;
}

.partners-study360__title::after {
	background-color: var(--color-grey);
	content: "";
	display: block;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	width: 100%;
	height: 1px;
	z-index: 1;
}

.partners-study360__content {
	display: flex;
	align-items: center;
	position: relative;
	text-align: center;
}

@media (min-width: 768px) {
	.partners-study360__content {
		min-height: 200px;
	}
}

.partners-study360__list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	z-index: 2;
}

.partners-study360__list-item {
	margin: 0.25rem;
}

@media (min-width: 768px) {
	.partners-study360__list-item {
		margin: 0.5rem;
	}
}

@media (min-width: 1024px) {
	.partners-study360__list-item {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
	}
}

.partners-study360__list-item__link {
	background-color: var(--color-white);
	border: 2px solid var(--color-blue);
	border-radius: 120px;
	display: inline-flex;
	list-style: none;
	overflow: hidden;
	padding: 0;
	align-items: center;
	text-decoration: none;
	transition: border-color 96ms linear;
	width: 120px;
	height: 120px;
	z-index: 2;
}

@media (min-width: 768px) {
	.partners-study360__list-item__link {
		border-radius: 150px;
		width: 150px;
		height: 150px;
	}
}

.partners-study360__list-item__link:active,
.partners-study360__list-item__link:focus,
.partners-study360__list-item__link:hover {
	border-color: var(--color-orange);
}

.partners-study360__list-item__link img {
	display: block;
	width: 100%;
}

.partners-study360__background {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	z-index: 1;
}
