.l-error {
	box-sizing: border-box;
	color: var(--color-blue);
	display: grid;
	padding: 16px;
	justify-content: center;
	align-items: center;
	height: 100%;
}

@media (min-width: 768px) {
	.l-error {
		height: calc(100% - 4rem);
	}
}

.l-error a {
	border-color: var(--color-alpha-blue-25);
	color: var(--color-blue);
}

.l-error a:hover {
	border-color: var(--color-blue);
}
