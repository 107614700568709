.l-styleguide .site-header,
.l-styleguide .site-container__fixed {
	position: relative;
}

.site-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 3.125rem;
	z-index: 100;
}

@media (min-width: 768px) {
	.site-header {
		min-height: 4rem;
	}
}

.site-header__logo {
	background-image: url("../images/logo.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	border: 0;
	display: block;
	margin-left: -1.75rem;
	position: absolute;
	text-indent: -9999rem;
	top: 0;
	left: 50%;
	width: 3.5rem;
	height: 4rem;
}

@media (min-width: 768px) {
	.site-header__logo {
		margin-left: 0;
		top: 0.6rem;
		left: 0;
		width: 4.75rem;
		height: 5.25rem;
	}
}

.site-header__stan {
	background-image: url("../images/powered-by-stan.svg");
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	top: 0;
	left: 2.75rem;
	width: 60px;
	height: 100%;
}

@media (min-width: 768px) {
	.site-header__stan {
		left: 6rem;
		width: 88px;
	}
}

.site-header__navigation {
	text-align: center;
}

@media (max-width: 767px) {
	.site-header__navigation {
		display: none;
	}
}

.site-header__navigation ul {
	list-style: none;
}

.site-header__navigation li {
	display: inline-block;
}

@media (min-width: 768px) and (max-width: 920px) {
	.site-header__navigation li:first-child {
		margin-left: 4.5rem;
	}
}

.site-header__navigation a {
	border-bottom: 0;
	color: var(--color-white);
	display: inline-block;
	font-weight: 700;
	letter-spacing: 0.05em;
	padding: 1.5rem 1rem;
	text-decoration: none;
	text-transform: uppercase;
}

@media (min-width: 1024px) {
	.site-header__navigation a {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

.site-header__navigation .is-active a {
	color: var(--color-orange);
}

.site-header__language-selector {
	font-size: 0.7rem;
	line-height: 3.125rem;
	position: absolute;
	text-transform: uppercase;
	top: 0;
	right: -0.25rem;
	bottom: 0;
}

@media (min-width: 768px) {
	.site-header__language-selector {
		line-height: 4rem;
	}
}

.site-header__language-selector a {
	border: 0;
	color: rgb(255 255 255 / 0.5);
	display: inline-block;
	padding: 0 0.5rem;
}

.site-header__language-selector a.is-active {
	color: rgb(255 255 255 / 1);
	font-weight: 700;
}

.site-header__action {
	position: absolute;
	top: 1.1rem;
	right: 3.5rem;
}

@media (max-width: 767px) {
	.site-header__action {
		display: none;
	}
}

.site-header__hamburger {
	border: 0;
	display: inline-block;
	margin-top: 0.5rem;
	padding: 0;
}

.site-header__hamburger .menu-toggle {
	background: none;
	border: 0;
	display: block;
	line-height: normal;
	overflow: visible;
	padding: 0;
	user-select: none;
}

.site-header__hamburger .icon {
	fill: var(--color-white);
	width: 2rem;
	height: 2rem;
}

.is-showing-navigation .site-header__hamburger .site-header__hamburger__open {
	display: none;
}

.site-header__hamburger .site-header__hamburger__close {
	display: none;
}

.is-showing-navigation .site-header__hamburger .site-header__hamburger__close {
	display: block;
}

@media (min-width: 768px) {
	.site-header__hamburger {
		display: none;
	}
}
