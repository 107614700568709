.partner-location {
	padding: 2rem 0 0;
}

.partner-location__title {
	color: var(--color-grey);
	display: flex;
	font-weight: 400;
	margin-bottom: 2rem;
	align-items: center;
	position: relative;
	text-align: center;
}

@media (min-width: 768px) {
	.partner-location__title {
		margin-bottom: 2rem;
	}
}

.partner-location__title span {
	display: inline-block;
	flex-shrink: 0;
	margin: 0 1rem;
	text-align: center;
}

.partner-location__title::before,
.partner-location__title::after {
	background-color: var(--color-grey);
	content: "";
	display: block;
	margin-top: -2px;
	width: 100%;
	height: 1px;
}

.partner-location__item {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	z-index: 2;
}

.partner-location__item__link {
	border: 0;
	text-decoration: none;
}

.partner-location__item__image {
	margin: 0.25rem 0;
}

@media (min-width: 768px) {
	.partner-location__item__image {
		margin: 0.5rem 0;
	}
}
