.location-picker {
	background-color: var(--color-orange);
	display: block;
	position: relative;
}

.location-picker__title {
	padding: 1.5rem 0 1.35rem;
	position: relative;
	z-index: 1;
}

@media (max-width: 767px) {
	.location-picker__title {
		box-shadow: none;
		padding-bottom: 0;
	}

	.location-picker__title::after {
		clear: both;
		content: "";
		display: table;
	}

	.location-picker__title .wrapper {
		margin: 0;
		width: 100%;
	}
}

.location-picker__title h1 {
	font-size: 1.25rem;
	line-height: 1.2;
	margin: 0 auto;
	padding: 1rem 0 2rem;
	text-align: center;
	width: 90%;
	max-width: 30rem;
}

@media (min-width: 768px) {
	.location-picker__title h1 {
		padding: 0;
	}
}

@media (min-width: 768px) and (max-height: 1024px) {
	.location-picker__title h1 {
		font-size: 1rem;
	}
}

@media (min-width: 768px) and (min-height: 1025px), (min-width: 1024px) and (min-height: 920px) {
	.location-picker__title h1 {
		font-size: 2rem;
	}
}

@media (max-width: 767px) {
	.location-picker__title .counter {
		border-radius: 0;
		box-sizing: border-box;
		float: left;
		font-size: 1rem;
		padding-top: 1.125rem;
		text-align: center;
		width: 50%;
	}
}

@media (min-width: 768px) {
	.location-picker__title .counter {
		margin-top: 2.5rem;
		position: absolute;
		top: 100%;
		right: -0.75rem;
	}
}

@media (min-width: 1024px) {
	.location-picker__title .counter {
		margin-top: -1.5rem;
	}
}

.location-picker__map {
	box-sizing: border-box;
	height: 40vh;
	min-height: 250px;
}

@media (min-width: 768px) {
	.location-picker__map {
		height: calc(100vh - 30rem);
		min-height: 480px;
	}
}

@media (min-width: 1024px) and (min-height: 920px) {
	.location-picker__map {
		min-height: 500px;
	}
}

.location-picker__map .mapboxgl-ctrl-top-right,
.location-picker__map .mapboxgl-ctrl-bottom-left,
.location-picker__map .mapboxgl-ctrl-bottom-right {
	display: none;
}

.location-picker__results {
	display: none;
}

.location-picker__result {
	background-color: var(--color-white);
	border-bottom: 1px solid #f0f0f0;
	overflow: hidden;
	padding: 1rem;
}

.location-picker__result a {
	border: 0;
	color: inherit;
	padding: 0;
}

@media (max-width: 567px) {
	.location-picker__result:nth-child(even) {
		background-color: var(--color-background);
	}
}

@media (min-width: 568px) {
	.location-picker__result:nth-child(odd) {
		border-right: 1px solid #f0f0f0;
	}

	.location-picker__result:nth-child(2),
	.location-picker__result:nth-child(3),
	.location-picker__result:nth-child(6) {
		background-color: var(--color-background);
	}
}

.location-picker__result__wrapper {
	display: flex;
	justify-content: space-between;
}

.location-picker__popup {
	font-family: var(--font-sans);
	overflow: hidden;
	padding: 5px 5px 0;
	width: 180px;
}

@media (min-width: 370px) {
	.location-picker__popup {
		width: 200px;
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.location-picker__popup {
		width: 360px;
	}
}

.location-picker__popup a {
	border: 0;
	color: inherit;
	padding: 0;
}

.location-picker__result-title {
	display: block;
	font-size: 1rem;
	letter-spacing: 0.05em;
	margin: 0.5rem 0;
	overflow-wrap: anywhere;
	text-transform: uppercase;
}

.location-picker__result-thumb {
	background-color: var(--color-background);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 3rem;
	display: none;
	float: left;
	position: relative;
	width: 6rem;
	height: 6rem;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.location-picker__result-thumb {
		display: block;
	}
}

.location-picker__result-meta {
	font-size: 0.8125rem;
	font-weight: 500;
	line-height: 1.45;
}

.location-picker__result-meta .icon {
	display: inline-block;
	fill: var(--color-orange);
	margin-right: 0.35rem;
	position: relative;
	top: 2px;
	width: 1rem;
	height: 1rem;
}

.location-picker__result-meta > span {
	display: block;
}

.location-picker__result-content {
	display: inline-block;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.location-picker__result-content {
		width: calc(100% - 7rem);
	}
}

.location-picker__result-content .icon {
	margin-right: 0.125rem;
	width: 0.75rem;
	height: 0.75rem;
}

.location-picker__result-content a {
	color: var(--color-orange);
	text-decoration: underline;
}

.location-picker .mapboxgl-popup-close-button {
	display: flex;
	font-family: var(--font-sans);
	font-size: 20px;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 5px;
	right: 5px;
	width: 20px;
	height: 20px;
}

/* #region Legend */
.location-picker__legend {
	display: flex;
	justify-content: center;
}

/* up-to-small */
@media (max-width: 767px) {
	.location-picker__legend {
		flex-wrap: wrap;
		padding: 1.5625rem 16px 0 16px;
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.location-picker__legend {
		padding-top: 2.5rem;
	}
}

/* up-to-small */
@media (max-width: 767px) {
	.location-picker__legend p {
		margin-bottom: 20px;
	}
}

.location-picker__legend p:not(:last-child) {
	margin-right: 30px;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.location-picker__legend p:not(:last-child) {
		margin-right: 60px;
		margin-bottom: 0;
	}
}

.location-picker__legend__item {
	display: flex;
	align-items: center;
}

.location-picker__legend__item::before {
	border-radius: 9999px;
	content: "";
	display: block;
	margin-right: 10px;
	width: 22px;
	height: 22px;
}

.location-picker__legend__item--available::before {
	background-color: var(--color-green);
}

.location-picker__legend__item--full::before {
	background-color: var(--color-red);
}

.location-picker__legend__item--closed::before {
	background-color: var(--color-grey-location-closed);
}
/* #endregion Legend */

.location-picker__filter {
	padding-top: 1.25rem;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.location-picker__filter {
		padding-top: 1.5rem;
	}
}
