.hero {
	box-sizing: border-box;
	padding: 5rem 0;
	position: relative;
	text-align: center;
	min-height: 200px;
	z-index: 1;
}

@media (min-width: 768px) {
	.hero {
		padding: 7rem 0;
		min-height: 360px;
	}
}

@media (min-width: 1024px) {
	.hero {
		padding: 10rem 0;
		min-height: 480px;
	}
}

.hero .wrapper {
	z-index: 2;
}

.hero p {
	color: var(--color-white);
}

.hero--no-image p {
	color: var(--color-black);
}

.hero__title {
	color: var(--color-white);
	margin: 0 auto;
	text-align: center;
}

.hero--no-image .hero__title {
	color: var(--color-orange);
}

.hero__subtitle {
	border-bottom: 2px solid var(--color-white);
	color: var(--color-white);
	display: inline-block;
	font-size: 1rem;
	font-weight: 700;
	letter-spacing: 0.05em;
	margin-bottom: 1.5rem;
	padding-right: 2px;
	padding-left: 2px;
	text-transform: uppercase;
}

.hero--no-image .hero__subtitle {
	border-bottom: 2px solid var(--color-black);
	color: var(--color-black);
}

.hero__shader {
	background-color: rgb(0 0 0 / 0.25);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.hero--no-image .hero__shader {
	background-color: transparent;
}

.hero__actions {
	display: block;
	position: relative;
	z-index: 1;
}

@media (max-width: 767px) {
	.hero__actions .wrapper {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.hero__actions {
		margin-top: -2.25rem;
	}
}

.hero__actions-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	max-width: 1230px;
}

.hero__action {
	background-color: var(--color-white);
	color: var(--color-grey);
	display: block;
	font-weight: 400;
	line-height: 2.65rem;
	position: relative;
	text-align: center;
	height: 2.5rem;
}

.hero__action--green {
	background-color: var(--color-green);
	color: var(--color-white);
}

.hero__action--intrinsic-height {
	height: auto;
}

@media (max-width: 767px) {
	.hero__action {
		box-sizing: border-box;
		display: flex;
		font-size: 1rem;
		font-weight: 700;
		line-height: 1.5;
		padding: 0 1.5rem 0;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		width: 50%;
		height: 7rem;
	}

	.hero__action--wide {
		width: 100%;
	}

	.hero__action--intrinsic-height {
		height: auto;
	}

	.hero__action--dark-on-mobile {
		background-color: #2b363a;
		color: var(--color-white);
	}

	.hero__action--dark-on-mobile .icon {
		display: none;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.hero__action {
		height: 6rem;
	}

	.hero__action--intrinsic-height {
		height: auto;
	}
}

@media (min-width: 768px) {
	.hero__action {
		box-shadow: 0 0 10px rgb(0 0 0 / 0.15);
		display: inline-block;
		flex: 0 0 calc(30vw - 0.5rem);
		font-size: 0.825rem;
		line-height: 4.35rem;
		margin: 0 0.5rem 1rem;
		height: 4.5rem;
	}

	.hero__action--wide {
		flex: 0 0 calc(60vw + 0.25rem);
	}

	.hero__action--intrinsic-height {
		height: auto;
	}
}

@media (min-width: 1024px) {
	.hero__action {
		flex: 0 0 30%;
		font-size: 1rem;
		margin: 0 0.5%;
	}

	.hero__action--wide {
		flex: 0 0 calc(60% + 1.5%);
		margin-top: 1%;
	}
}

.hero__action.is-actionable {
	background-color: var(--color-orange);
	border-bottom: none;
	color: var(--color-white);
	transition: background-color 96ms linear;
}

.hero__action.is-actionable span {
	border-bottom: 2px solid var(--color-white);
	color: var(--color-white);
	display: inline;
	font-weight: 700;
	position: relative;
	text-transform: uppercase;
}

@media (max-width: 767px) {
	.hero__action.is-actionable:nth-child(3) {
		width: 100%;
	}

	mr-currently-open + .hero__action.is-actionable {
		width: 100%;
	}

	.hero__action.is-actionable span {
		border-bottom: 0;
	}
}

.hero__action.is-actionable:focus,
.hero__action.is-actionable:hover {
	background-color: var(--color-blue);
}

@media (max-width: 767px) {
	.hero__action.is-distance {
		background-color: #fbfbfb;
		border-bottom: 1px solid #f0f0f0;
		clear: both;
		line-height: 2.65rem;
		width: 100%;
		height: 2.5rem;
	}
}

.hero__action .icon {
	display: inline-block;
	fill: var(--color-orange);
	margin-right: 0.25rem;
	position: relative;
	top: -1px;
	vertical-align: middle;
	width: 1rem;
	height: 1rem;
}

.hero__action span {
	color: var(--color-orange);
}

.hero__action-content {
	font-weight: inherit;
}

.hero__cover-image {
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: -1;
}

@supports (object-fit: cover) {
	.hero__cover-image {
		object-fit: cover;
		height: 100%;
	}
}

.hero__action__location-notice {
	font-size: 1rem;
	line-height: 1.5;
	padding: 20px;
	text-align: center;
	text-wrap: balance;
}

@media (min-width: 768px) {
	.hero__action__location-notice {
		font-size: 0.8rem;
	}
}
