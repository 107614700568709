.stan-social-navigation {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.stan-social-navigation__item {
	display: inline-block;
}

.stan-social-navigation__item + .stan-social-navigation__item {
	margin-left: 20px;
}

.stan-social-navigation__link {
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
	color: #ffffff;
	display: block;
	text-decoration: underline;
	width: 24px;
	height: 24px;
}

.stan-social-navigation__link[data-social-icon="facebook"] {
	background-image: url("../images/stan-facebook.svg");
}

.stan-social-navigation__link[data-social-icon="instagram"] {
	background-image: url("../images/stan-instagram.svg");
}

.stan-social-navigation__link[data-social-icon="youtube"] {
	background-image: url("../images/stan-youtube.svg");
}

.stan-social-navigation__link[data-social-icon="linkedin"] {
	background-image: url("../images/stan-linkedin.svg");
}

@media (min-width: 1024px) {
	.stan-social-navigation__link {
		width: 20px;
		height: 20px;
	}
}

.stan-social-navigation__link:focus {
	outline: 0;
}

.stan-social-navigation__link:hover {
	cursor: pointer;
}

.stan-social-navigation__link:focus,
.stan-social-navigation__link:hover {
	color: #00dd00;
}

.stan-social-navigation__link .icon {
	display: block;
	fill: currentColor;
	width: 100%;
	height: 100%;
}
