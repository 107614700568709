.stan-navigation-list {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.stan-navigation-list__item {
	margin-bottom: 10px;
}

.stan-navigation-list__link {
	color: currentColor;
	text-decoration: underline;
	transition: color 96ms linear;
}

.stan-navigation-list__link:focus {
	outline: 0;
}

.stan-navigation-list__link:focus,
.stan-navigation-list__link:hover {
	color: #00dd00;
	text-decoration: none;
}
