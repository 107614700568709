* {
	margin: 0;
	padding: 0;
}

html,
body {
	position: relative;
	height: 100%;
	min-height: 100%;
}

@media (max-width: 767px) {
	html {
		position: relative;
		height: 100%;
	}
}

body {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

@media (max-width: 767px) {
	body {
		position: relative;
		height: 100%;
	}

	body.is-showing-navigation {
		overflow: hidden;
	}
}

@media (min-width: 768px) {
	body {
		padding-top: 4rem;
	}
}

table {
	border-collapse: collapse;
	margin-bottom: 1.5rem;
	text-align: left;
	width: 100%;
}

table:not(.table--show-thead) thead {
	display: none;
}

table tbody tr:nth-child(odd) {
	background-color: #fff1e6;
}

table thead {
	background-color: var(--color-orange);
	color: var(--color-white);
}

table td,
table th {
	border-bottom: 1px dashed #ffdbbe;
	line-height: 1.35;
	padding: 0.75rem 0.5rem;
}

@media (min-width: 768px) {
	table td:first-child,
	table th:first-child {
		padding-left: 1rem;
	}

	table td:last-child,
	table th:last-child {
		padding-right: 1rem;
	}
}

table th {
	border-bottom: none;
}

table tr:last-child td {
	border-bottom: 0;
}

table span {
	display: block;
}

.table--students th {
	white-space: nowrap;
}

.table--students th:first-child,
.table--students td:first-child {
	width: 100%;
}

@media (max-width: 767px) {
	.site-container {
		position: relative;
		transition: transform 512ms ease-in-out;
		height: 100%;
	}

	.is-showing-navigation .site-container {
		transform: translateX(calc(100vw - 65px));
	}
}

@media (min-width: 568px) and (max-width: 767px) {
	.is-showing-navigation .site-container {
		transform: translateX(calc(100vw - 95px));
	}
}

.site-container__fixed {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1000;
}

@media (max-width: 767px) {
	.site-container__fixed {
		height: 3.125rem;
	}
}

@media (min-width: 768px) {
	.site-container__fixed {
		position: fixed;
		height: auto;
	}
}

.site-container__scrollable {
	position: relative;
	min-height: 100%;
	z-index: 1;
}

@media (max-width: 767px) {
	.site-container__scrollable {
		-webkit-overflow-scrolling: touch;
		box-sizing: border-box;
		overflow-y: scroll;
		padding-top: 3.125rem;
		height: 100%;
	}

	.is-showing-navigation .site-container__scrollable {
		pointer-events: none;
	}
}

[hidden][hidden] {
	display: none;
}

@supports (content-visibility: hidden) and (display: revert) {
	[hidden][hidden="until-found"] {
		content-visibility: hidden;
		display: revert;
	}
}
